video, button {
  visibility: hidden;
  position: absolute;
  bottom: 30px;
  left: 30px;
}

html {
  background-color: #000;
}

/*# sourceMappingURL=index.f5249211.css.map */
