video, button {
    position: absolute;
    bottom: 30px;
    left: 30px;

    visibility: hidden;
}

html {
    background-color: #000;
}